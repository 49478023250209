<!-- 加入我们 -->
<template>
    <div class="join-us">
        <div class="banner">
            <div class="info">
                <h2>更多<span>机遇</span>，更多<span>精彩</span></h2>
                <p>
                    与优秀的人一起工作，谈笑间获得经过时间沉淀的启示，在一个充满潜力的创业团队，实现“坐上火箭”的梦想。
                </p>
            </div>
        </div>
        <div class="content">
            <h2>招聘岗位</h2>
            <p>我们竭诚为你服务</p>
            <div class="details">
                <div class="item" v-for="(item, index) in itemList" :key="index">
                    <h4>{{ item.title }}</h4>
                    <h5>职位描述：</h5>
                    <p v-for="(item1, index1) in item.details" :key="index1">{{ item1 }}</p>
                    <span @click="toDetails()">联系我们</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            itemList: [
                {
                    title: "资深前端研发工程师",
                    details: [
                        "岗位职责：",
                        " 1、参与产品的需求和设计评审，并负责前端开发实现；",
                        " 2、负责/参与前端研发体系建设，涵盖研发、测试、监控等系统建设；",
						" 3、负责/参与前端技术框架建设；",
						" 4、对系统关键代码进行Review并提出改进方案；",
						"任职要求：",
						" 1、本科计算机相关专业，有5年以上开发经验，5年以上的前端开发经验；",
						" 2、精通Web前端技术（HTML/CSS/JavaScript等)以及掌握Vue、React等框架中的一种或两种；",
						" 3、对Javascript面向对象编程具有深入的了解；有足够Javascript项目开发经验，能够使用Javascript合理构建较为复杂的页面逻辑，对typescript 语法熟悉的优先；",
						" 4、熟悉常用设计模式，并在项目中有实际应用，且能对现有的业务场景架构出符合公司实际的应用框架。",
						" 5、熟练掌握小程序/App的开发，有HTML5/CSS3等移动端Web页面开发经验；",
						" 6、熟悉http/https协议和restfulapi接口标准，熟练掌握前后端交互的技术和常用的框架；",
						" 7、深刻理解Web标准，对可用性、可访问性、性能优化等有深入理解以及编程实践经验；",
						" 8、熟悉uniapp、taro等多端开发框架，熟练掌握多端开发的兼容性及开发经验；",
                    ],
                },
                {
                    title: "资深后端研发工程师",
                    details: [
                        "岗位职责：",
                        " 1、参与产品的需求和设计评审，并负责后端开发实现；",
                        " 2、负责所属产品的基本问题分析与解决；",
						" 3、负责产品单元测试代码编写，测试以及发布；",
						" 4、支持并参与产品交付以及相关技术文档撰写；",
						" 5、分析系统瓶颈，解决各种疑难杂症，对系统进行性能调优；",
						" 6、新技术预研；",
						" 7、针对业务需求可行性和设计方案进行评估；",
						" 8、对系统关键代码进行Review并提出改进方案；",
						"岗位要求：",
						" 1、本科及以上学历， 5 年以上 JAVA 后端开发经验；",
						" 2、精通 Java 编程，熟练掌握线程池和并发相关工具类；熟练掌握 Spring Boot、 Mybatis、 Spring Cloud 全系列组件；",
						" 3、熟悉 MySQL 数据库，且熟练掌握 SQL 优化和数据表索引设置，有分表经验者优先；",
						" 4、熟悉 Redis 缓存原理，对二级缓存搭建、 Redis 雪崩和击穿处理有一定了解；",
						" 5、熟悉 HTTP 协议，理解前后端分离的原理，有灰度发布经验者优先；",
						" 6、了解 JMS 或 AMQ，如 activemq， rabbitmq 等；",
						" 7、重视团队协作，善于沟通，对所负责开发的产品有强烈的主人翁意识；",
						" 8、自我管理能力强良好，崇尚团队合作，快速的学习能力，乐于分享与沟通",
						" 9、拥有良好的代码习惯，要求结构清晰，命名规范，逻辑性强，代码冗余率低",
						" 10、具有较强的团队意识，高度的责任感，对工作积极严谨，勇于承担压力",
                    ],
                },
                {
                    title: "市场销售总监",
                    details: [
                        "岗位职责：",
                        " 1、多元化工作模式，综合素质能力要求高；协助总经理，负责客户的开拓，维护与管理；",
                        " 2、采用顾问式服务的模式，让客户充分掌握公司产品功能，解决客户痛点问题，从而建立高度粘性，持续签约合作；产品主要以B端管理类应用软件为主；",
						" 3、收集市场信息，拓展公司的市场策略，完成公司在行业中的市场定位，及时提供市场反馈；",
						" 4、有良好的宏观视野和逻辑思维能力，表达清晰简洁，能够负责制定营销战略、营销策略以及推广计划；",
						"任职要求：",
						" 1、经验：具备B端或者政府端营销策划经验，熟悉产品和用户转换规则；",
						" 2、学历：全日制本科及以上学历，市场相关专业尤佳；",
						" 3、能力：具有较强的语言和演讲能力，喜欢并善于与人沟通，具有抗压能力，能主动协同部门间的合作；",
						" 4、态度：积极主动，性格开朗，有理想有目标，具有团队精神，有较强的责任心。",
                    ],
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 跳转联系我们
        toDetails() {
            this.$router.push({ path: "/contactUs" });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "joinUs_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "joinUs_mobile.scss";
}
</style>
